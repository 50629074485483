import React, { lazy, Suspense } from 'react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { LoadingState } from '@/components/LoadingState';
import { NavigationMenu } from '@/components/NavigationMenu';

import { LoginPage } from '@/pages/LoginPage';
import { PageNotFound } from '@/pages/PageNotFound';
import ErrorBoundary from '@/pages/ErrorBoundary';

import { StoreProvider } from '@/contexts/StoreContext';
import { WebSocketProvider } from '@/contexts/WebSocketContext';

TimeAgo.addDefaultLocale(en);

const ProductsDashboard = lazy(() => import('@/pages/ProductsDashboard'));
const OrdersDashboard = lazy(() => import('@/pages/OrdersDashboard'));
const CustomersDashboard = lazy(() => import('@/pages/CustomersDashboard'));
const MetafieldsDashboard = lazy(() => import('@/pages/MetafieldsDashboard'));
const ReviewsDashboard = lazy(() => import('@/pages/ReviewsDashboard'));
const DataFeedDashboard = lazy(() => import('@/pages/DataFeedDashboard'));

const GiftCertsDashboard = lazy(() => import('@/pages/GiftCertsDashboard'));
const ReportsDashboard = lazy(() => import('@/pages/ReportsDashboard'));
const FulfillmentDashboard = lazy(() => import('@/pages/FulfillmentDashboard'));
const BillingPage = lazy(() => import('@/pages/BillingPage'));

const theme = createTheme({
  palette: {
    background: {
      default: '#FCF4ED',
    },
    primary: {
      // main: '#fb8c00',
      main: '#8d6e63',
      contrastText: '#fff8f2',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

const apolloClient = new ApolloClient({
  uri: '/graphql',
  credentials: 'include',
  cache: new InMemoryCache({
    addTypename: false,
  }),
  onError: ({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        const isUnauthenticated = message.indexOf('Not Authorized') > -1;
        if (isUnauthenticated) {
          window.location = '/login';
        }
      });
    }
  },
});

const BlankComponent = () => <div />;

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ErrorBoundary>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <ApolloProvider client={apolloClient}>
          <StoreProvider>
            <Router>
              <WebSocketProvider>
                <Suspense fallback={<LoadingState />}>
                  <Switch>
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/">
                      <NavigationMenu>
                        <ProductsDashboard />
                      </NavigationMenu>
                    </Route>
                    <Route exact path="/orders">
                      <NavigationMenu>
                        <OrdersDashboard />
                      </NavigationMenu>
                    </Route>
                    <Route exact path="/customers">
                      <NavigationMenu>
                        <CustomersDashboard />
                      </NavigationMenu>
                    </Route>
                    <Route exact path="/product-metafields">
                      <NavigationMenu>
                        <MetafieldsDashboard />
                      </NavigationMenu>
                    </Route>
                    <Route exact path="/product-reviews">
                      <NavigationMenu>
                        <ReviewsDashboard />
                      </NavigationMenu>
                    </Route>
                    <Route exact path="/product-feed">
                      <NavigationMenu>
                        <DataFeedDashboard />
                      </NavigationMenu>
                    </Route>
                    <Route exact path="/gift-certs">
                      <NavigationMenu>
                        <GiftCertsDashboard />
                      </NavigationMenu>
                    </Route>
                    <Route exact path="/reports">
                      <NavigationMenu>
                        <ReportsDashboard />
                      </NavigationMenu>
                    </Route>
                    <Route exact path="/fulfillment">
                      <NavigationMenu>
                        <FulfillmentDashboard />
                      </NavigationMenu>
                    </Route>
                    <Route exact path="/billing">
                      <NavigationMenu>
                        <BillingPage />
                      </NavigationMenu>
                    </Route>
                    <Route
                      exact
                      path="/privacypolicy"
                      component={BlankComponent}
                    />
                    <Route exact path="/terms" component={BlankComponent} />
                    <Route component={PageNotFound} />
                  </Switch>
                </Suspense>
              </WebSocketProvider>
            </Router>
          </StoreProvider>
        </ApolloProvider>
      </SnackbarProvider>
    </ErrorBoundary>
  </ThemeProvider>
);

export default App;
