import { gql } from '@apollo/client';

const sharedFields = `
      slug
      profileType
      status
      lastRun
      fulfillmentPrimaryIdentifier
      fulfillmentIdentifierMapping
      fulfillmentTrackingLink
      fulfillmentTrackingNumber
      fulfillmentTrackingCarrier
      fulfillmentShippingProvider
      cronExpression
      profileName
      exportMethod
      fileExtension
      columnSeparator
      ftpHost
      ftpUsername
      decryptedFtpPassword
      ftpFilePath
      googleSheetUrl
      googleSheetName
      fileName
      scheduleEnabled
      scheduleType
      scheduleFrequency
      scheduleRunTime
      hourlyFrequency
      scheduleCustomDays
      latestLog {
        success
        remark
        startedBy
        totalExportedData
      }
`;
export const getAllProfiles = gql`
  query {
    allFulfillmentProfiles {
      ${sharedFields}
    }
  }
`;

export const createNewFulfillmentProfile = gql`
  mutation ($attributes: FulfillmentProfileInput!) {
    createFulfillmentProfile(attributes: $attributes) {
      message
      success
      profile {
        ${sharedFields}
      }
    }
  }
`;

export const editSchedule = gql`
  mutation ($slug: String!, $schedule: ScheduleInput!) {
    setFulfillmentSchedule(slug: $slug, schedule: $schedule) {
      message
      success
      profile {
        ${sharedFields}
      }
    }
  }
`;

export const duplicateProfile = gql`
  mutation ($slug: String!) {
    duplicateFulfillmentProfile(slug: $slug) {
      success
      message
      profile {
        ${sharedFields}
      }
    }
  }
`;

export const editProfile = gql`
  mutation ($slug: String!, $attributes: FulfillmentProfileInput!) {
    editFulfillmentProfile(slug: $slug, attributes: $attributes) {
      message
      success
      profile {
        ${sharedFields}
      }
    }
  }
`;
