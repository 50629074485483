import React, { useState } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  Drawer,
  AppBar,
  Button,
  Toolbar,
  List,
  Grid,
  Divider,
  IconButton,
  ListItem,
  Chip,
  ListItemText,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { navigationMenuStyle } from '@/config/style';
import logo from '@/images/logo.svg';
import ExpiredStore from '@/components/ExpiredStore';
import { useStore } from '@/hooks/useStore';

const navigationRoutes = [
  {
    destination: '/',
    key: 'Products',
    enabled: {
      bigcommerce: true,
      ecwid: true,
      wix: true,
    },
  },
  {
    destination: '/orders',
    key: 'Orders',
    enabled: {
      bigcommerce: true,
      ecwid: true,
      wix: true,
    },
  },
  {
    destination: '/customers',
    key: 'Customers',
    enabled: {
      bigcommerce: true,
      ecwid: true,
      wix: false,
    },
  },
  {
    destination: 'product-feed',
    key: 'Data/Shopping Feed (Google, Pinterest, Meta/Instagram)',
    enabled: {
      bigcommerce: true,
      ecwid: false,
      wix: true,
    },
  },
];

const miscRoutes = [
  {
    destination: '/fulfillment',
    key: 'Fulfill Orders',
    enabled: {
      bigcommerce: true,
      ecwid: false,
      wix: false,
    },
  },
];

const extraRoutes = [
  {
    destination: '/product-reviews',
    key: 'Product Reviews',
    enabled: {
      bigcommerce: true,
      ecwid: false,
      wix: false,
    },
  },
  {
    destination: '/reports',
    key: 'Analytical Reports',
    enabled: {
      bigcommerce: true,
      ecwid: false,
      wix: false,
    },
  },
];

// const reportRoutes = [
//   {
//     destination: '/reports',
//     key: 'Analytical Reports',
//     enabled: {
//       bigcommerce: true,
//       ecwid: false,
//       wix: false,
//     },
//   },
// ];

export const NavigationMenu = ({ children }) => {
  const { platform } = useStore();
  const classes = navigationMenuStyle();
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>

          <Grid container>
            <Grid container item xs={8} sm={4} md={2}>
              <img src={logo} alt="logo" />
            </Grid>
          </Grid>

          {/* Freshdesk Support Link */}
          {/*
          <Button
            color="inherit"
            variant="outlined"
            style={{ marginRight: 10 }}
            onClick={() => {
              window.open(
                'https://exportstash.freshdesk.com/support/tickets/new'
              );
            }}
          >
            Support
          </Button>
        */}
          <Button
            component={RouterLink}
            color="inherit"
            variant="outlined"
            style={{ marginRight: 10 }}
            to="/billing"
          >
            Billing
          </Button>

          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              window.open('https://exportstash.freshdesk.com/support/home');
            }}
          >
            Knowledge
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {navigationRoutes.map(
            (route) =>
              route.enabled[platform] && (
                <ListItem
                  component={RouterLink}
                  button
                  to={route.destination}
                  key={route.key}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={route.key}
                  />
                </ListItem>
              )
          )}

          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          {miscRoutes.map(
            (route) =>
              route.enabled[platform] && (
                <ListItem
                  component={RouterLink}
                  button
                  to={route.destination}
                  key={route.key}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={route.key}
                  />
                  {/* <Chip label="Beta" variant="outlined" size="small" /> */}
                </ListItem>
              )
          )}

          <Divider style={{ marginTop: 10, marginBottom: 10 }} />

          {extraRoutes.map(
            (route) =>
              route.enabled[platform] && (
                <ListItem
                  component={RouterLink}
                  button
                  to={route.destination}
                  key={route.key}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={route.key}
                  />
                </ListItem>
              )
          )}

          {/* <Divider style={{ marginTop: 10, marginBottom: 10 }} /> */}

          {/* {reportRoutes.map(
            (route) =>
              route.enabled[platform] && (
                <ListItem
                  component={RouterLink}
                  button
                  to={route.destination}
                  key={route.key}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={route.key}
                  />
                </ListItem>
              )
          )} */}
        </List>

        <div style={{ margin: '20px 10px 0px 10px' }}>
          <Button
            color="primary"
            variant="outlined"
            style={{ width: '100%' }}
            onClick={() => {
              window.open('https://exportstash.kampsite.co');
            }}
          >
            Submit Requests
          </Button>
        </div>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />

        <ExpiredStore />
        {children}
      </main>
    </div>
  );
};
